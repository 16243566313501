import * as React from 'react';
import styled from '../../theme';

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: white;
  padding: 20px 0;
  z-index: 10;
  position: relative;
  @media all and (min-width: 50em) {
    padding: 0;
  }
`;

const PaddingDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 60px 40px;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 10;
  @media all and (min-width: 50em) {
    height: 15vh;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const Text = styled.p`
  color: #4c4a57;
`;

export default class Footer extends React.Component<{}, {}> {
  render() {
    return (
      <Container>
        <PaddingDiv>
          <Text>
            &copy; {new Date().getFullYear()} Kyle Lutes All Rights Reserved.
          </Text>
          <Text>Designed and Developed by Kyle Lutes.</Text>
          <Text>Orlando, Florida.</Text>
        </PaddingDiv>
      </Container>
    );
  }
}
