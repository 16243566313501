import * as React from 'react';
import styled, { theme } from '../../theme';
import AnimatedLogo from '../atoms/AnimatedLogo';
import ScrollDown from '../atoms/ScrollDown';
import ScrollDownAlt from '../atoms/ScrollDownAlt';
import { debounce, getBrowserScrollTop } from '../../lib/utils';

interface IntroProps {
  current: string;
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
}

const IntroSection: React.SFC<IntroProps> = ({ className, children }) => (
  <div className={className}>{children}</div>
);

const Intro = styled(IntroSection)`
  z-index: 0;
  pointer-events: none;
  background: ${(props: IntroProps) => theme[props.current] || theme.default};
  overflow: hidden;
  position: relative;
  @media all and (min-width: 50em) {
    background: transparent;
    overflow: visible;
  }
`;

const HomeIntro = styled.section`
  position: relative;
  z-index: 2;
  @media all and (min-width: 50em) {
    position: fixed;
    left: 0px;
    right: 0px;
    height: 100vh;
  }
`;

interface Props {
  transform: boolean;
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
}

const C: React.SFC<Props> = ({ transform, className, children }) => (
  <div className={className}>{children}</div>
);

const CenterSection = styled(C)`
  transform: ${(props: Props) =>
    props.transform ? 'translateX(50%)' : 'translateX(0%)'};
  height: 100vh;
  transition: transform 1000ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  width: 100%;
  position: relative;
  z-index: 10;
`;

const LogoSection = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  margin-bottom: 0;
  @media all and (min-width: 50em) {
    margin-top: 0px;
  }
`;

const Blurb = styled.section`
  position: relative;
  z-index: 1;
  @media all and (min-width: 50em) {
    background: ${theme.home};
    width: 100%;
    height: 100vh;
    align-items: center;
    position: fixed;
    display: flex;
  }
`;

const BlurbInner = styled.div`
  padding-top: 0;
  position: relative;
  z-index: 10;
  padding: 60px 40px;
  @media all and (min-width: 50em) {
    width: 100%;
    padding: 20vh 165px 20vh 20%;
  }
`;

interface TitleProps {
  transform: boolean;
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
}
const Text: React.SFC<TitleProps> = ({ children, className, transform }) => (
  <span className={className}>{children}</span>
);

const AnimatedText = styled(Text)`
  opacity: ${(props: TitleProps) => (props.transform ? 1 : 0)};
  transform: ${(props: TitleProps) =>
    props.transform
      ? 'matrix(1, 0, 0, 1, 0, 0)'
      : 'translate(-20%, 0%) matrix(1, 0, 0, 1, 0, 0)'};
  transition: transform 1000ms cubic-bezier(0.7, 0, 0.3, 1) 0ms,
    opacity 500ms linear 450ms;
`;

const BlurbTitle = styled(AnimatedText)`
  display: inline-block;
  font-weight: 300;
  font-size: 1.1rem;
  margin-bottom: 25px;
  color: white;
`;

const TextBlock = styled.div`
  font-size: 1.8rem;
  line-height: 1.2;
  color: white;
  font-weight: 700;
  max-width: 200px;
  transition: 1000ms cubic-bezier(0.7, 0, 0.3, 1) 150ms,
    opacity 500ms linear 600ms;
  @media all and (min-width: 50em) {
    max-width: 500px;
    font-size: 2.4rem;
  }
`;

const Strong = styled.strong`
  color: #1c1b20;
  text-transform: uppercase;
`;

const ColorSection: React.SFC<Props> = ({ className }) => (
  <div className={className} />
);

const ColorBlock = styled(ColorSection)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  transform: ${(props: Props) =>
    props.transform ? 'translateX(100%)' : 'translateX(0%)'};
  transition: transform 1000ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;
  background: #f8f8f8;
  left: 50%;
  height: ${(props: Props) => (props.transform ? '100vh' : '250vh')};
`;

interface State {
  transform: boolean;
  scrollY: number;
  isScrolling: boolean;
}

interface HomeProps {
  current: string;
}

export default class Home extends React.Component<HomeProps, State> {
  constructor(props: HomeProps) {
    super(props);

    this.state = {
      transform: false,
      isScrolling: false,
      scrollY: null,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.debounceScroll = this.debounceScroll.bind(this);
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('scroll', this.handleScroll, true);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('scroll', this.handleScroll, true);
    }
  }

  debounceScroll = debounce(() => {
    if (this.state.isScrolling) {
      this.setState({ isScrolling: false, scrollY: null });
    }
  });

  handleScroll(e: Event) {
    const { isScrolling, scrollY } = this.state;
    if (window) {
      if (!isScrolling) {
        this.setState({ isScrolling: true, scrollY: getBrowserScrollTop() });
      }

      if (scrollY) {
        this.setState({
          transform: scrollY < getBrowserScrollTop(),
          scrollY: null,
        });
      }
      this.debounceScroll();
    }
  }
  render() {
    return (
      <Intro current={this.props.current}>
        <HomeIntro>
          <CenterSection transform={this.state.transform}>
            <LogoSection>
              <AnimatedLogo height="125px" width="125px" />
            </LogoSection>
          </CenterSection>
          <ScrollDown transform={this.state.transform} />
          <ScrollDownAlt transform={this.state.transform} />
          <ColorBlock transform={this.state.transform} />
        </HomeIntro>
        <Blurb>
          <BlurbInner>
            <BlurbTitle transform={this.state.transform}>Who am I?</BlurbTitle>
            <TextBlock id="textblock">
              <div>
                <AnimatedText transform={this.state.transform}>
                  <Strong>I'm Kyle Lutes,</Strong>
                </AnimatedText>
              </div>
              <div>
                <AnimatedText transform={this.state.transform}>
                  a creative developer with a passion for all things visual and
                  technical,
                </AnimatedText>
              </div>
              <div>
                <AnimatedText transform={this.state.transform}>
                  standing on the sweet spot where design and code intersect.
                </AnimatedText>
              </div>
            </TextBlock>
          </BlurbInner>
        </Blurb>
      </Intro>
    );
  }
}
