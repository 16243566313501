export const splitSentence = (s: string): string[] => {
  let middle = Math.floor(s.length / 2);
  const before = s.lastIndexOf(' ', middle);
  const after = s.indexOf(' ', middle + 1);
  if (middle - before < after - middle) {
    middle = before;
  } else {
    middle = after;
  }
  return [s.substr(0, middle), s.substr(middle + 1)];
};

export const debounce = (func: any) => {
  let timeout: any;
  return function(...args: any[]) {
    const context = this;

    const lastCall = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(lastCall, 100);
  };
};

export const encode = (data: any): string =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

export const getBrowserScrollTop = () =>
  window
    ? window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0
    : 0;
