import * as React from 'react';
import { Link } from 'gatsby';
import styled, { theme } from '../theme';
import Layout from '../components/layout';
import Container from '../components/sections/Container';
import Intro from '../components/sections/Home';
import TextSection from '../components/molecules/TextSection';
import ImageSection from '../components/atoms/ImageSection';
import Footer from '../components/organisms/Footer';

const IntroText = styled.p`
  max-width: 275px;
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 900;
  margin-bottom: 40px;
  color: #1c1b20;
  @media all and (min-width: 50em) {
    max-width: 100%;
    font-size: 3.2rem;
  }
`;

const IntroContent = styled.p`
  margin-bottom: 20px;
  @media all and (min-width: 50em) {
    font-size: 1.4rem;
  }
`;

const BlockContent = styled.div`
  @media all and (min-width: 50em) {
    width: 40%;
    padding-right: 12%;
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  font-style: italic;
  text-decoration: none;
  color: #1c1b20;
  background: none;
  border: none;
  &::after,
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    overflow: hidden;
    background: #1c1b20;
    z-index: 0;
    transform-origin: bottom-left;
    transition: transform 400ms cubic-bezier(1, 0, 0, 1) 0ms;
  }
  &::before {
    background: ${theme.home};
    bottom: 8px;
    top: 8px;
    height: auto;
    opacity: 0.5;
    left: -5px;
    right: -5px;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition-duration: 400ms;
  }
  &:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  &:hover::after {
    transform: scaleX(0);
    transform-origin: bottom right;
    transition-duration: 400ms;
  }
`;

interface Props {
  history?: History;
  location?: Location;
}

const HomePage: React.SFC<Props> = () => (
  <Layout>
    <Container current="home">
      <Intro current="home" />
      <ImageSection
        alt="Kyle Lutes Freelance Developer"
        srcSet="https://res.cloudinary.com/kyle-lutes/image/upload/c_crop,g_xy_center,h_220,w_350,x_300,y_300,e_grayscale/v1507300835/slopes_a9aywp.jpg 375w, https://res.cloudinary.com/kyle-lutes/image/upload/c_crop,g_xy_center,h_500,w_1500,x_300,y_300,e_grayscale/v1507300835/slopes_a9aywp.jpg 1280w"
        url="https://res.cloudinary.com/kyle-lutes/image/upload/c_crop,h_500,w_1212,e_grayscale/v1507300835/slopes_a9aywp.jpg"
      />
      <TextSection current="home" titleTop="About" titleBot="Me.">
        <BlockContent>
          <IntroText>
            I'm a <i>designer</i> and <i>developer</i> from Orlando, Florida.
          </IntroText>
          <IntroContent>
            I specialize in React applications, Node backends, and CMS
            implementations, like this very site!
          </IntroContent>
          <StyledLink to="/about">More about me</StyledLink>
        </BlockContent>
      </TextSection>
      <Footer />
    </Container>
  </Layout>
);

export default HomePage;
