import * as React from 'react';
import styled, { keyframes } from '../../theme';

interface PropsInner {
  transform: boolean;
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
}
const C: React.SFC<PropsInner> = ({ children, className }) => (
  <span className={className}>{children}</span>
);

const Container = styled(C)`
  display: none;
  @media all and (min-width: 50em) {
    display: block;
    position: fixed;
    left: 40px;
    line-height: 1;
    pointer-events: none;
    z-index: 101;
    transform: rotate(-90deg);
    bottom: ${(props: PropsInner) => (props.transform ? '80px;' : '-100px')};
    transition: bottom 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  }
`;

const ScrollDownText = styled.span`
  font-weight: 700;
  font-size: 1rem;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
`;

const ScrollLetters = styled.span`
  display: inline-block;
  color: #1c1b20;
  transition: transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  transform: translateX(5px);
`;

const bounce = keyframes`
from {
  transform: translateX(0px);
}
to {
  transform: translateX(-15px);
}
`;
const Arrow = styled.i`
  animation: ${bounce} 800ms cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
  display: inline-block;
  vertical-align: middle;
  color: #1c1b20;
`;

interface Props {
  transform: boolean;
}

const ScrollDown: React.SFC<Props> = ({ transform }) => (
  <Container transform={transform}>
    <Arrow className="fa fa-long-arrow-left" />
    <ScrollDownText>
      <ScrollLetters>S</ScrollLetters>
      <ScrollLetters>C</ScrollLetters>
      <ScrollLetters>R</ScrollLetters>
      <ScrollLetters>O</ScrollLetters>
      <ScrollLetters>L</ScrollLetters>
      <ScrollLetters>L</ScrollLetters>
    </ScrollDownText>
  </Container>
);

export default ScrollDown;
