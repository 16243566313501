import * as React from 'react';
import styled, { theme } from '../../theme';

const Section = styled.section`
  background: #f8f8f8;
  position: relative;
  z-index: 10;
  margin-top: 0;
  @media all and (min-width: 50em) {
    margin-top: 200vh;
  }
`;

const Padding = styled.div`
  position: relative;
  z-index: 10;
  padding: 60px 40px;
  @media all and (min-width: 50em) {
    padding: 20vh 165px 20vh 20%;
  }
`;

interface TitleProps {
  current: string;
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
}
const H2: React.SFC<TitleProps> = ({ children, className }) => (
  <h2 className={className}>{children}></h2>
);

const Title = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${(props: TitleProps) => theme[props.current] || theme.default};
  margin-bottom: 40px;
  @media all and (min-width: 50em) {
    margin-bottom: 100px;
  }
`;

const TitleLines = styled.span`
  font-size: 5rem;
  line-height: 0.9;
  @media all and (min-width: 50em) {
    font-size: 10rem;
  }
`;

interface Props {
  current: string;
  titleTop: string;
  titleBot?: string;
  children?: React.ReactChild | React.ReactChild[];
}

const TextSection: React.SFC<Props> = ({
  current,
  titleTop,
  titleBot,
  children,
}) => (
  <Section>
    <Padding>
      <Title current={current}>
        <TitleLines>{titleTop}</TitleLines>
        <TitleLines>{titleBot}</TitleLines>
      </Title>
      {children}
    </Padding>
  </Section>
);

export default TextSection;
