import * as React from 'react';
import styled, { keyframes } from '../../theme';

interface PropsInner {
  transform: boolean;
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
}
const C: React.SFC<PropsInner> = ({ children, className }) => (
  <span className={className}>{children}</span>
);

const Container = styled(C)`
  position: absolute;
  line-height: 1;
  transform: ${(props: PropsInner) =>
    props.transform
      ? 'rotate(-90deg) translateY(-60px) translateX(-100px)'
      : 'rotate(-90deg) translateY(-60px)'};
  opacity: ${(props: PropsInner) => (props.transform ? 0 : 1)};
  pointer-events: none;
  left: 50%;
  bottom: 110px;
  z-index: 101;
  transition: transform 1000ms cubic-bezier(0.7, 0, 0.3, 1) 0ms,
    opacity 250ms linear 0ms;
  @media all and (min-width: 50em) {
    bottom: 90px;
  }
`;

const ScrollDownText = styled.span`
  font-weight: 700;
  font-size: 1rem;
  margin-left: 10px;
  color: white;
  display: inline-block;
  vertical-align: middle;
`;

const ScrollLetters = styled.span`
  display: inline-block;
  transition: transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  transform: translateX(5px);
`;

const bounce = keyframes`
from {
  transform: translateX(0px);
}
to {
  transform: translateX(-15px);
}
`;
const Arrow = styled.i`
  animation: ${bounce} 800ms cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
  display: inline-block;
  vertical-align: middle;
  color: white;
`;

interface Props {
  transform: boolean;
}

const ScrollDown: React.SFC<Props> = ({ transform }) => (
  <Container transform={transform}>
    <Arrow className="fa fa-long-arrow-left" />
    <ScrollDownText>
      <ScrollLetters>S</ScrollLetters>
      <ScrollLetters>C</ScrollLetters>
      <ScrollLetters>R</ScrollLetters>
      <ScrollLetters>O</ScrollLetters>
      <ScrollLetters>L</ScrollLetters>
      <ScrollLetters>L</ScrollLetters>
    </ScrollDownText>
  </Container>
);

export default ScrollDown;
