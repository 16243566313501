import * as React from 'react';
import styled from '../../theme';

const Container = styled.div`
  display: block;
  background: #f8f8f8;
  transform: translate3D(0, 0, 0);
  width: 100%;
  order: 1;
  @media all and (min-width: 50em) {
    display: none;
  }
`;

const FluidImage = styled.img`
  width: 100%;
  max-width: 100%;
  display: block;
  height: auto;
`;

interface Props {
  url: string;
  srcSet: string;
  alt?: string;
}

const ImageSection: React.SFC<Props> = ({ url, srcSet, alt }) => (
  <Container>
    <FluidImage src={url} srcSet={srcSet} alt={alt} />
  </Container>
);

export default ImageSection;
